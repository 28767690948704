const AlgoliaSearch = require('algoliasearch');
const AlgoliaSearchHelper = require('algoliasearch-helper');
const Mustache = require('mustache');

const HITS_PER_PAGE = 5;

export default function () {
    const container = document.querySelector('[data-app-search]');

    if (!container) {
        return;
    }

    const activeResultCssClass = 'search__result--focus';
    const activeSummaryCssClass = 'search__summary--active';

    const config = JSON.parse(container.dataset.appSearch);
    const form = container.querySelector('[data-app-search-form]');
    const results = container.querySelector('[data-app-search-results]');
    const summary = container.querySelector('[data-app-search-summary]');
    const infoResults = container.querySelector('[data-app-search-info-results]');
    const infoEmpty = container.querySelector('[data-app-search-info-empty]');
    const template = container.querySelector('[data-app-search-template]').innerHTML;
    const templateDelimiters = ['[[', ']]'];
    const input = container.querySelector('[data-app-search-input]');
    const client = AlgoliaSearch(config.appId, config.apiKey);
    const helper = AlgoliaSearchHelper(client, config.indexName, { hitsPerPage: HITS_PER_PAGE });

    let currentResult;
    let currentValue;
    let removeClickListener;

    function search() {
        const value = input.value.trim();

        // Return if there is no value
        if (!value.length) {
            summary.classList.remove(activeSummaryCssClass);

            return;
        }

        // Do nothing (especially do not re-render result list or keyboard navigation would be broken)
        if (value === currentValue) {
            return;
        }

        currentValue = value;

        helper.setQuery(value);
        helper.search();
    }

    function showSummary() {
        summary.classList.add(activeSummaryCssClass);

        // Hide summary on click outside the container
        const outsideClickListener = (event) => {
            if (!container.contains(event.target)) {
                summary.classList.remove(activeSummaryCssClass);

                // eslint-disable-next-line no-use-before-define
                removeClickListener();
            }
        };

        removeClickListener = () => document.removeEventListener('click', outsideClickListener);
        document.addEventListener('click', outsideClickListener);
    }

    // Prepare the template
    Mustache.parse(template, templateDelimiters);

    helper.on('result', (content) => {
        results.innerHTML = '';

        // Show and update info bar
        if (content.results.nbHits > 0) {
            let message = infoResults.dataset.appSearchInfoResults;

            if (content.results.nbHits > HITS_PER_PAGE) {
                message = infoResults.dataset.appSearchInfoResultsTooMany;
            }

            infoResults.innerText = message.replace('{results}', HITS_PER_PAGE).replace('{total}', content.results.nbHits);
            infoResults.style.display = 'block';
            infoEmpty.style.display = 'none';
        } else {
            infoResults.style.display = 'none';
            infoEmpty.style.display = 'block';
        }

        // Generate the items markup
        content.results.hits.forEach((item) => {
            results.innerHTML += Mustache.render(template, item, {}, templateDelimiters);
        });

        // Show the summary at the end
        showSummary();
    });

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        search(e);
    });

    input.addEventListener('focus', () => {
        if (input.value.trim().length > 0) {
            showSummary();
        }
    });

    input.addEventListener('keyup', search);

    // Prevent moving the caret in the input field with arrow up/down keys – this is reserved for results navigation (see below)
    input.addEventListener('keydown', (e) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
        }
    });

    // Navigate through open(!) search results
    document.addEventListener('keyup', (e) => {
        if (!summary.classList.contains(activeSummaryCssClass) || results.children.length === 0) {
            return;
        }

        e.preventDefault();

        currentResult = results.querySelector(`.${activeResultCssClass}`);

        switch (e.key) {
        case 'ArrowDown':
            if (!currentResult) {
                results.children[0].classList.add(activeResultCssClass);
            } else {
                currentResult.classList.remove(activeResultCssClass);

                if (currentResult.nextElementSibling) {
                    currentResult.nextElementSibling.classList.add(activeResultCssClass);
                } else {
                    results.children[0].classList.add(activeResultCssClass);
                }
            }
            break;
        case 'ArrowUp':
            if (!currentResult) {
                results.children[results.children.length - 1].classList.add(activeResultCssClass);
            } else {
                currentResult.classList.remove(activeResultCssClass);

                if (currentResult.previousElementSibling) {
                    currentResult.previousElementSibling.classList.add(activeResultCssClass);
                } else {
                    results.children[results.children.length - 1].classList.add(activeResultCssClass);
                }
            }
            break;
        case 'Escape':
            summary.classList.remove(activeSummaryCssClass);
            removeClickListener();
            break;
        case 'Enter':
            if (currentResult) {
                window.location.href = currentResult.href;
            }
            break;
        }
    });
}
