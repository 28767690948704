import * as tocbot from 'tocbot';

export default function () {
    tocbot.init({
        tocSelector: '[data-toc]',
        contentSelector: '.layout-main',
        headingSelector: 'h2, h3, h4',
        hasInnerContainers: true,
        basePath: window.location.pathname,
    });
}
