import GLightbox from 'glightbox/src/js/glightbox';

export default function () {
    // get all lightbox links
    const links = Array.from(document.querySelectorAll('a[data-lightbox]'));

    // determine all lightbox groups
    const groups = [];

    links.forEach((link) => {
        if (!groups.includes(link.dataset.lightbox)) {
            groups.push(link.dataset.lightbox);
        }
    });

    groups.forEach((group) => {
        GLightbox({
            selector: `[data-lightbox="${group}"]`,
            onOpen: () => {
                moveCloseButtonInside();
            },
        });
    });

    function moveCloseButtonInside() {
        const closeBtn = document.querySelector('.gclose');
        const modalContainer = document.querySelector('.gslider');
        if (closeBtn && modalContainer) {
            modalContainer.appendChild(closeBtn);
        }
    }
}
