import AnchorJS from 'anchor-js';

export default function () {
    const anchors = new AnchorJS();

    anchors.options.visible = 'always';
    anchors.add('.layout-main h2, .layout-main h3, .layout-main h4');

    // Fix the URLs
    anchors.elements.forEach((el) => {
        el.querySelector('a.anchorjs-link').href = `${window.location.pathname}#${el.id}`;
    });
}
