import { sentryWrapper } from '../../app/_helpers/sentry';

import initAnchors from './components/anchors';
import initLightbox from './components/lightbox';
import initMenu from './components/menu';
import initNavigation from './components/navigation';
import initSearch from './components/search';
import initToc from './components/table-of-contents';
import {loadForElements} from '../../app/_helpers/module-loader';

sentryWrapper(() => {
    document.addEventListener('DOMContentLoaded', () => {
        initAnchors();
        initLightbox();
        initMenu();
        initNavigation();
        initSearch();
        initToc();

        loadForElements(document.querySelectorAll('[data-snippet]'), () => import('./components/syntax-highlighter'));
    });
});
