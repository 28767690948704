export default function () {
    const menuTrigger = document.querySelector('.page-menu-trigger');

    if (!menuTrigger) {
        return;
    }

    menuTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        document.body.classList.toggle('show-page-menu');
    });
}
