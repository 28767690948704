export default function () {
    const cssClass = 'accordion-active';

    function init(list) {
        if (!list) {
            return;
        }

        getChildren(list, 'li').forEach((el) => {
            const item = el;
            const sublist = getChild(item, 'ul');

            if (sublist) {
                // Slide the sublist on item click
                getChildren(item, 'a, strong').forEach((link) => {
                    link.addEventListener('click', (e) => {
                        if (!item.classList.contains(cssClass) && sublist.style.display === 'none') {
                            e.preventDefault();
                            item.classList.add(cssClass);
                            slideDown(sublist);
                        }

                        // Hide all sibling lists
                        getSiblings(item).forEach((sibling) => {
                            sibling.classList.remove(cssClass);
                            getChildren(sibling, 'ul').forEach((siblingChildUl) => {
                                slideUp(siblingChildUl);
                            });
                        });
                    });
                });

                // Hide the sublist if the item is not active
                if (!item.classList.contains('active') && !item.classList.contains('trail')) {
                    sublist.style.display = 'none';
                } else {
                    item.classList.add(cssClass);
                }

                // Recursively initialize the nested lists
                init(sublist);
            }
        });
    }

    function slideUp(target, duration = 300) {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = `${duration}ms`;
        target.style.boxSizing = 'border-box';
        target.style.height = `${target.offsetHeight}px`;
        // eslint-disable-next-line no-unused-expressions
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    function slideDown(target, duration = 300) {
        target.style.removeProperty('display');
        let { display } = window.getComputedStyle(target);

        if (display === 'none') display = 'block';

        target.style.display = display;
        const height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        // eslint-disable-next-line no-unused-expressions
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = `${duration}ms`;
        target.style.height = `${height}px`;
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    function getSiblings(el) {
        return [...el.parentElement.children].filter(node => node.nodeType === 1 && node !== el);
    }

    function getChildren(parent, selector) {
        return [...parent.children].filter(node => node.matches(selector));
    }

    function getChild(parent, selector) {
        return getChildren(parent, selector)[0];
    }

    [...document.querySelectorAll('.layout-aside .mod_navigation')].forEach((el) => {
        init(getChild(el, 'ul'));
    });
}
